import {
  ErrorMessage,
  Field,
  FieldProps,
  useField,
  useFormikContext
} from 'formik';
import { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';

type TextInputProps = {
  name: string;
  label: ReactNode;
  placeholder: string;
};

export const TextInput = ({ label, name, placeholder }: TextInputProps) => {
  const [field, meta] = useField(name);
  const { isSubmitting } = useFormikContext();
  return (
    <div className="flex flex-col grow gap-y-1">
      <label className="w-full font-medium">
        {label}{' '}
        {meta.error && (
          <span className="text-xs text-red-500">{meta.error}</span>
        )}
        {/* <ErrorMessage
          render={(msg) => {
            console.log({ name: field.name, msg });
            return <span className="text-xs text-red-500">{msg}</span>;
          }}
          name={field.name}
        /> */}
      </label>
      <input
        type="text"
        disabled={isSubmitting}
        className="px-4 py-2 border-[1px] border-gray-200-electripure outline-none"
        placeholder={placeholder}
        {...field}
      />
    </div>
  );
};
