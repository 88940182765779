export const rateSchedule = ['6', '6A', '6B', '8', '9'];

export const timezones = [
  {
    id: 'America/Adak',
    aliases: [ 'America/Atka', 'US/Aleutian' ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Alaska - western Aleutians',
      latitude: 51.88,
      longitude: -176.658055555556
    },
    offsets: [ '-10', '-09' ],
    currentOffset: '-09 (HDT)',
    nextTransition: {}
  },
  {
    id: 'America/Anchorage',
    aliases: [ 'US/Alaska' ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Alaska (most areas)',
      latitude: 61.2180555555556,
      longitude: -149.900277777778
    },
    offsets: [ '-09', '-08' ],
    currentOffset: '-08 (AKDT)',
    nextTransition: {}
  },
  {
    id: 'America/Boise',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Mountain - ID (south), OR (east)',
      latitude: 43.6136111111111,
      longitude: -116.2025
    },
    offsets: [ '-07', '-06' ],
    currentOffset: '-06 (MDT)',
    nextTransition: {}
  },
  {
    id: 'America/Chicago',
    aliases: [ 'CST6CDT', 'US/Central' ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Central (most areas)',
      latitude: 41.85,
      longitude: -87.65
    },
    offsets: [ '-06', '-05' ],
    currentOffset: '-05 (CDT)',
    nextTransition: {}
  },
  {
    id: 'America/Denver',
    aliases: [
      'America/Shiprock',
      'MST7MDT',
      'Navajo',
      'US/Mountain'
    ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Mountain (most areas)',
      latitude: 39.7391666666667,
      longitude: -104.984166666667
    },
    offsets: [ '-07', '-06' ],
    currentOffset: '-06 (MDT)',
    nextTransition: {}
  },
  {
    id: 'America/Detroit',
    aliases: [ 'US/Michigan' ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Eastern - MI (most areas)',
      latitude: 42.3313888888889,
      longitude: -83.0458333333333
    },
    offsets: [ '-05', '-04' ],
    currentOffset: '-04 (EDT)',
    nextTransition: {}
  },
  {
    id: 'America/Indiana/Indianapolis',
    aliases: [
      'America/Fort_Wayne',
      'America/Indianapolis',
      'US/East-Indiana'
    ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Eastern - IN (most areas)',
      latitude: 39.7683333333333,
      longitude: -86.1580555555556
    },
    offsets: [ '-05', '-04' ],
    currentOffset: '-04 (EDT)',
    nextTransition: {}
  },
  {
    id: 'America/Indiana/Knox',
    aliases: [ 'America/Knox_IN', 'US/Indiana-Starke' ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Central - IN (Starke)',
      latitude: 41.2958333333333,
      longitude: -86.625
    },
    offsets: [ '-06', '-05' ],
    currentOffset: '-05 (CDT)',
    nextTransition: {}
  },
  {
    id: 'America/Indiana/Marengo',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Eastern - IN (Crawford)',
      latitude: 38.3755555555556,
      longitude: -86.3447222222222
    },
    offsets: [ '-05', '-04' ],
    currentOffset: '-04 (EDT)',
    nextTransition: {}
  },
  {
    id: 'America/Indiana/Petersburg',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Eastern - IN (Pike)',
      latitude: 38.4919444444444,
      longitude: -87.2786111111111
    },
    offsets: [ '-06', '-05', '-04' ],
    currentOffset: '-04 (EDT)',
    nextTransition: {}
  },
  {
    id: 'America/Indiana/Tell_City',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Central - IN (Perry)',
      latitude: 37.9530555555556,
      longitude: -86.7613888888889
    },
    offsets: [ '-06', '-05' ],
    currentOffset: '-05 (CDT)',
    nextTransition: {}
  },
  {
    id: 'America/Indiana/Vevay',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Eastern - IN (Switzerland)',
      latitude: 38.7477777777778,
      longitude: -85.0672222222222
    },
    offsets: [ '-05', '-04' ],
    currentOffset: '-04 (EDT)',
    nextTransition: {}
  },
  {
    id: 'America/Indiana/Vincennes',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Eastern - IN (Da, Du, K, Mn)',
      latitude: 38.6772222222222,
      longitude: -87.5286111111111
    },
    offsets: [ '-06', '-05', '-04' ],
    currentOffset: '-04 (EDT)',
    nextTransition: {}
  },
  {
    id: 'America/Indiana/Winamac',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Eastern - IN (Pulaski)',
      latitude: 41.0513888888889,
      longitude: -86.6030555555556
    },
    offsets: [ '-06', '-05', '-04' ],
    currentOffset: '-04 (EDT)',
    nextTransition: {}
  },
  {
    id: 'America/Juneau',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Alaska - Juneau area',
      latitude: 58.3019444444444,
      longitude: -134.419722222222
    },
    offsets: [ '-09', '-08' ],
    currentOffset: '-08 (AKDT)',
    nextTransition: {}
  },
  {
    id: 'America/Kentucky/Louisville',
    aliases: [ 'America/Louisville' ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Eastern - KY (Louisville area)',
      latitude: 38.2541666666667,
      longitude: -85.7594444444444
    },
    offsets: [ '-05', '-04' ],
    currentOffset: '-04 (EDT)',
    nextTransition: {}
  },
  {
    id: 'America/Kentucky/Monticello',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Eastern - KY (Wayne)',
      latitude: 36.8297222222222,
      longitude: -84.8491666666667
    },
    offsets: [ '-06', '-05', '-04' ],
    currentOffset: '-04 (EDT)',
    nextTransition: {}
  },
  {
    id: 'America/Los_Angeles',
    aliases: [ 'PST8PDT', 'US/Pacific' ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Pacific',
      latitude: 34.0522222222222,
      longitude: -118.242777777778
    },
    offsets: [ '-08', '-07' ],
    currentOffset: '-07 (PDT)',
    nextTransition: {}
  },
  {
    id: 'America/Menominee',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Central - MI (Wisconsin border)',
      latitude: 45.1077777777778,
      longitude: -87.6141666666667
    },
    offsets: [ '-06', '-05' ],
    currentOffset: '-05 (CDT)',
    nextTransition: {}
  },
  {
    id: 'America/Metlakatla',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Alaska - Annette Island',
      latitude: 55.1269444444445,
      longitude: -131.576388888889
    },
    offsets: [ '-09', '-08' ],
    currentOffset: '-08 (AKDT)',
    nextTransition: {}
  },
  {
    id: 'America/New_York',
    aliases: [ 'EST5EDT', 'US/Eastern' ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Eastern (most areas)',
      latitude: 40.7141666666667,
      longitude: -74.0063888888889
    },
    offsets: [ '-05', '-04' ],
    currentOffset: '-04 (EDT)',
    nextTransition: {}
  },
  {
    id: 'America/Nome',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Alaska (west)',
      latitude: 64.5011111111111,
      longitude: -165.406388888889
    },
    offsets: [ '-09', '-08' ],
    currentOffset: '-08 (AKDT)',
    nextTransition: {}
  },
  {
    id: 'America/North_Dakota/Beulah',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Central - ND (Mercer)',
      latitude: 47.2641666666667,
      longitude: -101.777777777778
    },
    offsets: [ '-07', '-06', '-05' ],
    currentOffset: '-05 (CDT)',
    nextTransition: {}
  },
  {
    id: 'America/North_Dakota/Center',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Central - ND (Oliver)',
      latitude: 47.1163888888889,
      longitude: -101.299166666667
    },
    offsets: [ '-06', '-05' ],
    currentOffset: '-05 (CDT)',
    nextTransition: {}
  },
  {
    id: 'America/North_Dakota/New_Salem',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Central - ND (Morton rural)',
      latitude: 46.845,
      longitude: -101.410833333333
    },
    offsets: [ '-07', '-06', '-05' ],
    currentOffset: '-05 (CDT)',
    nextTransition: {}
  },
  {
    id: 'America/Phoenix',
    aliases: [ 'America/Creston', 'MST', 'US/Arizona' ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'MST - AZ (except Navajo)',
      latitude: 33.4483333333333,
      longitude: -112.073333333333
    },
    offsets: [ '-07' ],
    currentOffset: '-07 (MST)',
    nextTransition: {}
  },
  {
    id: 'America/Sitka',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Alaska - Sitka area',
      latitude: 57.1763888888889,
      longitude: -135.301944444444
    },
    offsets: [ '-09', '-08' ],
    currentOffset: '-08 (AKDT)',
    nextTransition: {}
  },
  {
    id: 'America/Yakutat',
    aliases: [],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Alaska - Yakutat',
      latitude: 59.5469444444444,
      longitude: -139.727222222222
    },
    offsets: [ '-09', '-08' ],
    currentOffset: '-08 (AKDT)',
    nextTransition: {}
  },
  {
    id: 'Pacific/Honolulu',
    aliases: [ 'HST', 'Pacific/Johnston', 'US/Hawaii' ],
    location: {
      countryCode: 'US',
      countryName: 'United States',
      comment: 'Hawaii',
      latitude: 21.3069444444444,
      longitude: -157.858333333333
    },
    offsets: [ '-10' ],
    currentOffset: '-10 (HST)',
    nextTransition: {}
  }
]
