import { TextInput } from '@/common/Input/Text';
import { useState } from 'react';

export const SecondaryContactForm = () => {
  const [addSecondaryContact, setAddSecondaryContact] =
    useState<boolean>(false);

  const handleShowAddSecondaryContact = () => setAddSecondaryContact(true);

  const handleHideAddSecondaryContact = () => setAddSecondaryContact(false);
  return (
    <div>
      {addSecondaryContact ? (
        <div className="flex flex-col gap-y-2 mt-3">
          <div className="flex justify-between">
            <p className="text-blue-300-electripure text-md font-semibold">
              Secondary Contact
            </p>
            <p
              className="text-red-500 cursor-pointer"
              onClick={handleHideAddSecondaryContact}>
              Remove contact
            </p>
          </div>
          <TextInput
            name="secondaryContact.fullname"
            label="Full Name"
            placeholder="John Doe"
          />
          <div className="flex gap-x-4">
            <TextInput
              label="Email"
              name="secondaryContact.email"
              placeholder="email@company.com"
            />
            <TextInput
              label="Cell phone"
              name="secondaryContact.cellphone"
              placeholder="(***) *** - ****"
            />
          </div>
        </div>
      ) : (
        <span
          className="font-bold text-blue-100-electripure cursor-pointer"
          onClick={handleShowAddSecondaryContact}>
          + add secondary contact
        </span>
      )}
    </div>
  );
};
