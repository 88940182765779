import { Field, useField, useFormikContext } from 'formik';
import { ReactNode } from 'react';

type TextareaProps = {
  rows: number;
  label: ReactNode;
  name: string;
  placeholder: string;
};

export const Textarea = ({ rows, label, name, placeholder }: TextareaProps) => {
  const [field, meta] = useField(name);
  const { isSubmitting } = useFormikContext();
  return (
    <div className="flex flex-col gap-y-1">
      <label className="w-full font-medium" htmlFor={field.name}>
        {label}
      </label>
      <textarea
        name={field.name}
        disabled={isSubmitting}
        rows={rows}
        placeholder={placeholder}
        className="px-4 py-2 border-[1px] border-gray-200-electripure outline-none"
      />
    </div>
  );
};
