import {
  Chart as ChartJS,
  CategoryScale,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  TickOptions
} from 'chart.js';
import { Fragment, useEffect, useState, useRef, memo, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

import './style.css';
import {
  AxisDictGraph,
  ColorGraph,
  DataGraph,
  DataGraphYConfig,
  YConfig,
  yLabel
} from '../../../interfaces/graph';
import { ChartContext } from '../../../context/GraphContext';
import { set } from 'firebase/database';
import { formatNumberToFiveDigits } from '../../../utils';

// Config chart js
ChartJS.register(
  zoomPlugin,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface ILineGraphSimple {
  _data: DataGraph;
  axisDict: AxisDictGraph;
  onZoom: any;
  backgroundRanges: {
    from: number;
    to: number;
    color: string;
    // onClick: () => void;
  }[];
  title: string;
  showTooltip: boolean;
}

function generateScales(axisDict: AxisDictGraph): any {
  let scales: any = {};

  Object.keys(axisDict).forEach((key: string) => {
    scales[key] = {
      type: axisDict[key].type,
      display: true,
      position: axisDict[key].position,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: any, index: any, ticks: any) {
          let valueStr: string = `${value}`;
          const regEx = new RegExp('[0-9]+[.,][0-9]+');
          if (!regEx.test(valueStr)) {
            valueStr = value.toFixed(1);
          }
          return valueStr.replaceAll(',', '.');
        }
      }
    };
    if (axisDict[key].hasOwnProperty('min')) {
      scales[key].min = axisDict[key].min;
    }
    if (axisDict[key].hasOwnProperty('max')) {
      scales[key].max = axisDict[key].max;
    }
  });
  console.log({ scales });
  return scales;
}

function LineGraphSimple({
  _data, // Data for graph
  title, // Title of graph
  showTooltip, // Show / hide tooltip
  onZoom, // Zoom event
  backgroundRanges = [], // Background ranges
  axisDict // Config for y axis
}: ILineGraphSimple) {
  console.log('LineGraphSimple');
  console.log('Data', _data);

  const { setIsLiveMode, chart, syncZoom, zoomState } =
    useContext(ChartContext);

  function startFetch({ chart }: { chart: ChartJS }) {
    const { min, max } = chart.scales.x;
    if (!(min == 0 && max == _data.x.length - 1)) {
      onZoom(min, max);
      setIsLiveMode(false);
      chart.resetZoom('none');
    }
  }

  // Options
  const options: any = {
    responsive: true,
    animation: {
      duration: 0
    },
    interaction: {
      intersect: false,
      mode: 'index',
      axis: 'xy'
    },
    elements: {
      point: {
        radius: 0
      }
    },
    scales: {
      ...generateScales(axisDict),
      x: {
        ticks: {
          maxTicksLimit: 15,
          display: _data.x_config.showAxis
        },
        min: zoomState.min,
        max: zoomState.max
      },
      y: {
        ticks: {
          callback: (value: any) => {
            // console.log('XXXXXXX', value);
            if (value != 'original') {
              return formatNumberToFiveDigits(value.toFixed(2));
            } else {
              return value;
            }
            // return value;
          }
          //value && formatNumberToFiveDigits(value.toFixed(2))
        },
        min: _data.scales.y?.min,
        max: _data.scales.y?.max
      }
    },
    plugins: {
      zoom: {
        limits: {
          x: {
            min: 0,
            max: 'original'
          },
          y: {
            min: 0,
            max: 100
          }
        },
        zoom: {
          drag: {
            enabled: true
          },
          mode: 'x',
          onZoom: ({ chart }) => {
            const { min, max } = chart.scales.x;

            syncZoom({ min, max });
          }
        }
      },
      legend: {
        display: false,
        position: 'bottom' as const
      },
      title: {
        display: true,
        text: title,
        // LINK https://www.chartjs.org/docs/latest/configuration/title.html
        fullSize: true, // If false, the box is sized and placed above/beside the chart area.
        position: 'left',
        // color: 'blue',
        padding: 0,
        font: {
          size: 18,
          weight: 'bold'
        }
      },
      maintainAspectRatio: false,
      tooltip: {
        enabled: showTooltip,
        intersect: true,
        callbacks: {
          title: (tooltipItem: any) => {
            return `${_data.x[tooltipItem[0].dataIndex]}`;
          },
          labelColor: (tooltipItem: any) => {
            return {
              backgroundColor: tooltipItem.dataset.backgroundColor,
              borderWidth: 0,
              borderColor: tooltipItem.dataset.backgroundColor
            };
          }
        }
      }
    }
  };

  const source = {
    labels: _data.x_labels,
    datasets: _data.y_config.map(
      (yConfig: DataGraphYConfig, index: number): any => {
        const pointStyle: string[] = [];
        const pointColor: string[] = [];
        const pointRadius: number[] = [];
        if (_data.y && _data.y.length > 0 && _data.y[index]) {
          // _data.y[index].map((_: any, _index: number) => {
          //   const type = _data.y_type_point[index][_index];
          //   if (type && type === 'Error') {
          //     pointStyle.push('circle');
          //     pointColor.push('rgba(255, 0, 0, 1)');
          //     pointRadius.push(2);
          //   } else if (type && type === 'Warning') {
          //     pointStyle.push('circle');
          //     pointColor.push('rgba(255, 255, 0, 1)');
          //     pointRadius.push(2);
          //   } else {
          //     pointStyle.push('circle');
          //     pointColor.push(yConfig.color);
          //     pointRadius.push(0);
          //   }
          // });
        }
        return {
          label: yConfig.label,
          yAxisID: yConfig.groupAxis,
          data: _data.y[index],
          borderWidth: 1,
          borderColor: yConfig.color,
          backgroundColor: yConfig.color,
          pointStyle,
          pointBackgroundColor: pointColor,
          pointBorderColor: pointColor,
          pointRadius,
          pointHoverRadius: [4, 0],
          hidden: !yConfig.isShow
        };
      }
    )
  };

  return (
    <Fragment>
      <div className="max-w-full max-h-full h-full px-[30px]">
        <Line
          ref={chart}
          className="w-full h-full max-w-full max-h-full"
          options={options}
          data={source}
        />
      </div>
    </Fragment>
  );
}

export default memo(LineGraphSimple);
