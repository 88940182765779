import { ROLES } from '@/utils/roles';
import * as yup from 'yup';

export const CreateCompanySchema = yup.object().shape({
  companyName: yup.string().required('This field is required'),
  companyDescription: yup.string().optional(),
  primaryAddress: yup.string().required('This field is required'),
  secondaryAddress: yup.string().optional(),
  city: yup.string().required('This field is required'),
  state: yup.string().trim().required('This field is required'),
  zipCode: yup.string().required('This field is required')
});

export const CreateSiteSchema = yup.object().shape({
  companyId: yup.string().required('This field is required'),
  siteName: yup.string().required('This field is required'),
  address: yup.string().required('This field is required'),
  secondaryAddress: yup.string().optional(),
  city: yup.string().required('This field is required'),
  state: yup.string().trim().required('This field is required'),
  zipCode: yup.string().required('This field is required'),
  timeZone: yup.string().trim().required('This field is required'),
  utilityCompany: yup.string().optional(),
  rateSchedule: yup.string().optional()
});

export const CreateMeterSchema = yup.object().shape({
  name: yup.string().required('This field is required'),
  meterId: yup.string().required('This field is required'),
  applianceId: yup.string().required('This field is required'),
  rating: yup.string().required('This field is required'),
  switchgear: yup.string().required('This field is required'),
  transformer: yup.string().required('This field is required'),
  serialNumber: yup.string().required('This field is required'),
  size: yup.string().required('This field is required'),
  siteId: yup.string().required('This field is required')
});

export const CreateUserSchema = yup.object().shape({
  fullname: yup.string().required('This field is required'),
  email: yup
    .string()
    .required('This field is required')
    .email('Enter a valid email address'),
  cellphone: yup.string().required('This field is required'),
  companyIds: yup.array().of(yup.number()).min(1, 'This field is required'),
  siteIds: yup.array().of(yup.number()).min(1, 'This field is required'),
  role: yup
    .string()
    .oneOf(Object.values(ROLES))
    .required('This field is required'),
  emergencyContact: yup
    .object()
    .shape({
      fullname: yup.string().test({
        name: 'emergency-contact-fullname-test',
        test: function (value) {
          const { email, cellphone } = this.parent;
          const hasAnyField = email || cellphone || value;

          if (!hasAnyField || value) return true;

          return this.createError({
            path: this.path,
            message: 'This field is required'
          });
        }
      }),
      email: yup
        .string()
        .email('Enter a valid email address')
        .test({
          name: 'emergency-contact-email-test',
          test: function (value) {
            const { fullname, cellphone } = this.parent;
            const hasAnyField = fullname || value || cellphone;

            if (!hasAnyField || value) return true;

            return this.createError({
              path: this.path,
              message: 'This field is required'
            });
          }
        }),
      cellphone: yup.string().test({
        name: 'emergency-contact-cellphone-test',
        test: function (value) {
          const { fullname, email } = this.parent;
          const hasAnyField = fullname || email || value;

          if (!hasAnyField || value) return true;

          return this.createError({
            path: this.path,
            message: 'This field is required'
          });
        }
      })
    })
    .notRequired(),
  secondaryContact: yup
    .object()
    .shape({
      fullname: yup.string().test({
        name: 'emergency-contact-fullname-test',
        test: function (value) {
          const { email, cellphone } = this.parent;
          const hasAnyField = email || cellphone || value;

          if (!hasAnyField || value) return true;

          return this.createError({
            path: this.path,
            message: 'This field is required'
          });
        }
      }),
      email: yup
        .string()
        .email('Enter a valid email address')
        .test({
          name: 'emergency-contact-email-test',
          test: function (value) {
            const { fullname, cellphone } = this.parent;
            const hasAnyField = fullname || value || cellphone;

            if (!hasAnyField || value) return true;

            return this.createError({
              path: this.path,
              message: 'This field is required'
            });
          }
        }),
      cellphone: yup.string().test({
        name: 'emergency-contact-cellphone-test',
        test: function (value) {
          const { fullname, email } = this.parent;
          const hasAnyField = fullname || email || value;

          if (!hasAnyField || value) return true;

          return this.createError({
            path: this.path,
            message: 'This field is required'
          });
        }
      })
    })
    .notRequired()
});

export const UpdateSiteSchema = yup.object().shape({
  siteName: yup.string().required('This field is required'),
  address: yup.string().required('This field is required'),
  secondaryAddress: yup.string().optional(),
  city: yup.string().required('This field is required'),
  state: yup.string().trim().required('This field is required'),
  zipCode: yup.string().required('This field is required'),
  timeZone: yup.string().trim().required('This field is required'),
  utilityCompany: yup.string().optional(),
  rateSchedule: yup.string().optional()
});
