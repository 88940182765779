import './index.css';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import { electripureReducer } from './reducers/electipure';
import ReactDOM from 'react-dom/client';
import AppRouter from './routers/AppRouter';
import environment from './config/env';
import AuthContext from './context/AuthContext';
import React, { createContext } from 'react';
import NavContext from './context/NavContext';
import GraphContext from './context/GraphContext';
import { store as toolkitStore } from '@/store';
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
  reducer: electripureReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
      <NavContext>
        <AuthContext>
          <GraphContext>
            <AppRouter />
          </GraphContext>
        </AuthContext>
      </NavContext>
  </Provider>
);

{
  /* <FirebaseAppProvider firebaseConfig={environment.FIREBASE_CONFIG}>

</FirebaseAppProvider> */
}
