import { Form, Formik } from 'formik';
import { ReactNode, useState } from 'react';
import env from '@/config/env';
import { Dropdown } from '@/common/Input/Dropdown';
import { FileInput } from '@/common/Input/File';
import { TextInput } from '@/common/Input/Text';
import { Textarea } from '@/common/Input/Textarea';
import { CreateCompanyFormData } from '@/utils/types';
import { CameraIcon } from '@/assets/svg';
import { Button } from '@/common/Button';
import { CreateCompanySchema } from '@/schemas';
import { toast } from 'react-toastify';
import ElectripureService from '@/service/electripure-service';
import { ObjectRequestMapper } from '@/mappers/request.mapper';
import { sendGetOverview } from '@/actions/electripure';
import { useDispatch } from 'react-redux';
import { EntityForm } from '../EntityForm';

type CreateCompanyFormProps = {
  toggleModal: ((key: string) => void) | (() => void);
};

export const CreateCompanyForm = ({ toggleModal }: CreateCompanyFormProps) => {
  const [file, setFile] = useState<{
    dirty: boolean;
    file?: File;
    error?: string;
  }>({
    dirty: false
  });

  const dispatch = useDispatch();

  const handleSubmit = async (values: CreateCompanyFormData) => {
    const { file: logo } = file;

    const objectMap = {
      companyName: 'name',
      companyDescription: 'description',
      primaryAddress: 'address',
      secondaryAddress: 'address',
      city: 'city',
      state: 'state',
      zipCode: 'zip',
      logo: 'image'
    };

    const body = ObjectRequestMapper.map<
      CreateCompanyFormData & { logo?: File }
    >({ ...values, logo }, objectMap);

    const formData = new FormData();
    Object.entries(body).map(([key, value]) => formData.append(key, value));

    try {
      const response = await ElectripureService.createCompanyV2(formData);
      console.log(response);

      toggleModal('company');
      dispatch(sendGetOverview({}));
      toast('Company created successfully!', {
        type: 'success',
        position: 'bottom-right'
      });
    } catch (error: any) {
      console.error(error);
      toast(`Could not create company: ${error.message}`, {
        type: 'error',
        position: 'bottom-right'
      });
    }
  };

  const handleFileChange = (file: File) => {
    setFile((prev) => ({ ...prev, file, dirty: true, error: '' }));
  };

  const statesDropdownOptions: {
    value: any;
    text: string | ReactNode;
  }[] = [{ value: '', text: <span>Select State</span> }].concat(
    env.STATES.map((state) => ({
      value: state,
      text: <span>{state}</span>
    }))
  );

  return (
    <div className="bg-white z-30 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[985px] p-8">
      <EntityForm title="Let's get some basic company information">
        <Formik<CreateCompanyFormData>
          initialValues={{
            companyName: '',
            companyDescription: '',
            primaryAddress: '',
            secondaryAddress: '',
            city: '',
            state: statesDropdownOptions[0].value,
            zipCode: ''
          }}
          validationSchema={CreateCompanySchema}
          onSubmit={(values) => handleSubmit(values)}>
          {({ isSubmitting }) => (
            <Form>
              <div className="flex gap-x-6">
                <FileInput
                  className="self-start"
                  name="logo"
                  onChange={handleFileChange}>
                  {({ openFileDialog }) => (
                    <>
                      <div className="bg-gray-200-electripure relative flex items-center justify-center w-44 h-44">
                        <p className="text-white font-bold text-center">
                          {file.file ? file.file.name : 'Add company logo'}
                        </p>
                        <div
                          className="absolute bottom-0 right-0 flex flex-col items-center bg-black p-2 cursor-pointer"
                          onClick={openFileDialog}>
                          <CameraIcon />
                          <span className="text-sm font-semibold text-white">
                            Add
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </FileInput>
                <div className="flex flex-col grow w-full gap-y-2">
                  <TextInput
                    name="companyName"
                    label="Company Name"
                    placeholder="Company Name"
                  />
                  <Textarea
                    name="companyDescription"
                    rows={5}
                    placeholder="Company description"
                    label={
                      <>
                        Company Description{' '}
                        <span className="italic">optional</span>
                      </>
                    }
                  />
                  <TextInput
                    name="primaryAddress"
                    label="Primary Address"
                    placeholder="12345 Street Address"
                  />
                  <TextInput
                    name="secondaryAddress"
                    label={
                      <>
                        Address 2 <span className="italic">optional</span>
                      </>
                    }
                    placeholder="Suite 890"
                  />
                  <div className="flex gap-x-8">
                    <TextInput name="city" label="City" placeholder="City" />
                    <Dropdown
                      name="state"
                      options={statesDropdownOptions}
                      label="State"
                      defaultValue={statesDropdownOptions[0].value}
                    />
                    <TextInput
                      name="zipCode"
                      label="Zipcode"
                      placeholder="Zip"
                    />
                  </div>
                </div>
              </div>
              <Button
                type="submit"
                disabled={isSubmitting}
                className="text-sm mt-4 mx-auto flex jutify-center disabled:bg-blue-50-electripure bg-blue-100-electripure text-white h-10 w-40 rounded-md items-center outline-none">
                <p className="text-center w-full">Add</p>
              </Button>
            </Form>
          )}
        </Formik>
      </EntityForm>
    </div>
  );
};
