export class ObjectRequestMapper {
  static map<T extends {}>(
    objectToMap: T,
    mapObject: { [key in keyof T]: any }
  ): T {
    return Object.entries(objectToMap).reduce((acc, curr) => {
      const [key, value] = curr;

      const mappedKey = mapObject[key as keyof T] || key;

      if (value && typeof value === 'object' && !Array.isArray(value)) {
        acc[mappedKey] = ObjectRequestMapper.map(
          value,
          mapObject[key as keyof T] || {}
        );
      } else acc[mappedKey] = value;

      return acc;
    }, {} as any);
  }
}
