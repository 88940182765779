import { ReactNode } from 'react';

type ButtonProps = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  type: 'submit' | 'button';
};
export const Button = ({
  children,
  onClick,
  className,
  type,
  disabled
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={`${
        className
          ? className
          : 'outline-none bg-gray-200-electripure text-gray-300-electripure'
      }`}
      onClick={onClick}>
      {children}
    </button>
  );
};
