import { useMemo, useState } from 'react';
import { ModalMiddle } from '../../components/Modal';
import { ICompany, ISite } from './interfaces';
import LocationInfo from './components/LocationInfo';
import ContactInfo from './components/ContactInfo';
import SiteUpdateForm, { ISiteForm } from '../../components/Form/SiteForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteSite,
  sendCreateMDP,
  sendGetCompanyDetail,
  sendUpdateSite,
  setSiteAsDefault
} from '../../actions/electripure';
import { toast } from 'react-toastify';
import { RoleGuard } from '@/guards/RoleGuard';
import { PERMISSIONS } from '@/utils/roles';
import { ElectripureState } from '@/interfaces/reducers';
import MDPForm, { IMdpForm } from '@/components/Form/MDPForm';
import { AverageValuesTable } from '@/components/AverageTableValues';
import { DataTable } from '@/common/DataTable';
import { UploadedFiles } from './components/UploadedFiles';
import { Modal } from '@mui/material';
import { CreateSiteForm } from '@/common/Form/CreateSite';
import { UpdateSiteForm } from '@/common/Form/UpdateSite';
import { CreateDeviceForm } from '@/common/Form/CreateDevice';

type TableHeaders = {
  key:
    | 'MDPname'
    | 'meterId'
    | 'switchgear'
    | 'transformer'
    | 'transformerSerialNumber'
    | 'breaker';
  label: string;
};

type TableBody = {
  [key in TableHeaders['key']]: any;
};

const SiteDetail = ({ site, company }: { site: any; company: ICompany }) => {
  const headers: TableHeaders[] = [
    {
      key: 'MDPname',
      label: 'MDP Name'
    },
    {
      key: 'meterId',
      label: 'Meter ID'
    },

    {
      key: 'switchgear',
      label: 'Switch Gear Rating [A]'
    },
    {
      key: 'breaker',
      label: 'Breaker Rating [A]'
    },
    {
      key: 'transformer',
      label: 'Transformer Rating [kVA]'
    },
    {
      key: 'transformerSerialNumber',
      label: 'Transformer Serial Number'
    }
  ];

  const [showModalUpdateSite, setShowModalUpdateSite] = useState(false);
  const [showModalCreateMdp, setShowModalCreateMdp] = useState(false);

  const [showModal, setShowModal] = useState<{ site: boolean; mdp: boolean }>({
    site: false,
    mdp: false
  });

  const tableData = useMemo(() => {
    const initialData: TableBody[] = [];
    console.log(site.mdps[0]);
    return site.mdps.reduce((acc, curr) => {
      const rowData = {
        MDPname: curr.name,
        meterId: curr.meter_id,
        switchgear: curr.switchgear_rating ?? 'No value',
        transformer: curr.transformer_rating ?? 'No value',
        transformerSerialNumber: curr.serial_number ?? 'No value',
        breaker: 'No value'
      };

      acc.push(rowData);

      return acc;
    }, initialData);
  }, [site]);

  const dispatch = useDispatch();

  const viewMode = useSelector((state: ElectripureState) => state.viewMode);

  const submitUpdateSite = (site: Partial<ISiteForm>) => {
    dispatch(
      sendUpdateSite({
        site_id: site.id,
        schematic_id: site.schematic_id,
        name: site.name,
        address: site.address,
        address2: site.address2,
        city: site.city,
        state: site.state,
        zip: site.zip,
        logo_id: site.logo_id,
        payment: site.payment,
        manager_email: site.manager_email,
        manager_fullname: site.manager_fullname,
        manager_phone: site.manager_phone,
        company_id: company.id
      })
    );
    setShowModalUpdateSite(false);
  };

  function submitCreateMdp(mdp: Partial<IMdpForm>) {
    dispatch(
      sendCreateMDP({
        ...mdp,
        gmt: undefined,
        gmt_id: mdp.gmt,
        site_id: site.id,
        company_id: company.id
      })
    );
  }

  const handleDelete = async () => {
    try {
      const success = await deleteSite(site.id);
      if (success) {
        setShowModalUpdateSite(false);
        toast('Site deleted successfully', {
          type: 'success',
          position: 'bottom-right'
        });
        return dispatch(sendGetCompanyDetail({ cia_id: company.id }));
      }
    } catch (error) {
      toast('Error should be here', {
        type: 'error',
        position: 'bottom-right'
      });
    }
  };

  // const handleSetSiteAsDefault = async () => {
  //   try {
  //     const result = await dispatch(setSiteAsDefault(site.id))
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  return (
    <>
      <div className="bg-white border w-full p-[40px] overflow-x-hidden flex flex-col gap-y-8 justify-between items-start">
        <div className="w-full flex gap-x-10">
          <div className="flex gap-x-5 w-2/6">
            {site.site_image && (
              <div className="rounded border flex w-28 h-28 p-[5px]">
                <img className="rounded w-full" src={site.site_image} />
              </div>
            )}
            <div className="font-medium w-64">
              <h1 className="text-2xl">{site.name}</h1>
              {/* {viewMode === 'overview' && <span className="text-blue-100-electripure" onClick={() => handleSetSiteAsDefault}>Set as default</span>} */}
            </div>
          </div>
          <div className="flex gap-x-12 grow">
            <LocationInfo
              location={{
                address: site.address,
                address2: site.address2,
                city: site.city,
                state: site.state,
                zip: site.zip
              }}
            />
            <ContactInfo
              className="max-w-52"
              contact={{
                phone: site.contact_phone,
                email: site.contact_email,
                name: site.contact_name
              }}
            />
          </div>
          {viewMode === 'mdp' && (
            <div className="flex gap-x-6">
              <RoleGuard allowedPermissions={[PERMISSIONS.EDIT_SITE]}>
                <button
                  className="outline-none border self-start py-2 px-8"
                  onClick={() =>
                    setShowModal((prev) => ({ ...prev, site: true }))
                  }>
                  Edit
                </button>
              </RoleGuard>
              <RoleGuard allowedPermissions={[PERMISSIONS.CREATE_MDP]}>
                <button
                  className="outline-none border self-start py-2 px-8"
                  onClick={() =>
                    setShowModal((prev) => ({ ...prev, mdp: true }))
                  }>
                  + Add MDP
                </button>
              </RoleGuard>
            </div>
          )}
        </div>
        <hr className="ml-3 w-full" />
        {viewMode === 'mdp' ? (
          site.mdps.length > 0 && (
            <DataTable<TableHeaders[], TableBody[]>
              headers={headers}
              data={tableData}
            />
          )
        ) : (
          <div className="w-full flex items-center gap-x-8">
            <AverageValuesTable mdps={site.mdps} />
            <div className="w-[1px] h-[250px] bg-gray-300" />
            <UploadedFiles />
          </div>
        )}
      </div>
      <Modal
        open={showModal.site}
        className="relative"
        onClose={() => setShowModal((prev) => ({ ...prev, site: false }))}>
        <UpdateSiteForm
          companyId={company.id}
          initialValues={site}
          siteId={site.id}
          toggleModal={() => {
            setShowModal((prev) => ({ ...prev, site: false }));
            dispatch(sendGetCompanyDetail({ cia_id: company.id }));
          }}
        />
      </Modal>

      <Modal
        open={showModal.mdp}
        className="relative"
        onClose={() => {
          setShowModal((prev) => ({ ...prev, mdp: false }));
          dispatch(sendGetCompanyDetail({ cia_id: company.id }));
        }}>
        <CreateDeviceForm companyId={company.id} siteId={site.id} />
      </Modal>

      {/* <ModalMiddle
        show={showModalUpdateSite}
        onClose={() => {
          setShowModalUpdateSite(false);
        }}>
        <SiteUpdateForm
          site={{
            id: site.id,
            address: site.address,
            address2: site.address2,
            city: site.city,
            payment: site.payment,
            state: site.state,
            zip: site.zip,
            name: site.name,
            schematic_url: site.schedule_image,
            logo_url: site.site_image,
            logo_id: site.id_image,
            schematic_id: site.id_esquematico,
            manager_email: site.contact_email,
            manager_fullname: site.contact_name,
            manager_phone: site.contact_phone
          }}
          title="Edit Site information"
          button="Update"
          onSubmit={submitUpdateSite}
          onDelete={handleDelete}
          type="update"
        />
      </ModalMiddle>

      <ModalMiddle
        show={showModalCreateMdp}
        onClose={() => {
          setShowModalCreateMdp(false);
        }}>
        <MDPForm
          schematic={site.schedule_image}
          mdp={{}}
          onSubmit={submitCreateMdp}
          type="create"
        />
      </ModalMiddle> */}
    </>
  );
};

export default SiteDetail;
