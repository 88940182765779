import { TextInput } from '@/common/Input/Text';

export const EmergencyContactForm = () => (
  <div className="flex flex-col gap-y-2 mt-3">
    <p className="text-blue-300-electripure text-md font-semibold">
      Emergency Contact
    </p>
    <TextInput
      name="emergencyContact.fullname"
      label="Full Name"
      placeholder="John Doe"
    />
    <div className="flex gap-x-4">
      <TextInput
        label="Email"
        name="emergencyContact.email"
        placeholder="email@company.com"
      />
      <TextInput
        label="Cell phone"
        name="emergencyContact.cellphone"
        placeholder="(***) *** - ****"
      />
    </div>
  </div>
);
