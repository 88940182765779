import { ErrorMessage, useField } from 'formik';

type RadioButtonGroup = {
  name: string;
  label: string;
  options: {
    id: string;
    value: string;
    label: string;
    checked?: boolean;
  }[];
};

export const RadioButton = ({ name, label, options }: RadioButtonGroup) => {
  const [field, meta] = useField(name);

  return (
    <div>
      <span>{label}</span>
      <div className="grid grid-cols-2">
        {options.map((option) => (
          <div className="flex gap-x-2">
            <input
              type="radio"
              {...field}
              id={option.id}
              value={option.value}
              checked={field.value === option.value}
            />
            <label htmlFor={option.id}>{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};
