import { isValidFileType } from '@/utils';
import { FileType } from '@/utils/enum';
import { useField } from 'formik';
import { ChangeEvent, forwardRef, ReactNode, useRef, useState } from 'react';

type FileRenderProps = {
  openFileDialog: () => void;
};

type FileInputProps = {
  className?: string;
  onChange?: (
    file: File,
    name: string,
    isValidFileType?: boolean
  ) => void;
  children: ((props: FileRenderProps) => ReactNode) | ReactNode;
  name: string;
  validFileTypes?: FileType[];
};

export const FileInput = ({
  children,
  onChange,
  name,
  validFileTypes,
  ...rest
}: FileInputProps) => {
  const [field, meta] = useField(name);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File>();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files[0]) {
      if (onChange) {
        const [file] = files;
        onChange(
          file,
          field.name,
          validFileTypes
            ? isValidFileType(files[0].type, validFileTypes)
            : undefined
        );
      }
    }
  };

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  return (
    <div {...rest}>
      {typeof children === 'function'
        ? children({
            openFileDialog
          })
        : children}
      <input
        type="file"
        className="hidden"
        ref={fileInputRef}
        {...file}
        onChange={handleFileChange}
      />
    </div>
  );
};
